import React, { useContext, useEffect, useState, useRef } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";
import { useClickAway } from "react-use";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageBackgroundColor } from "../components/context/page-background-color";

// Components
import { PageSEO } from "../components/seo/page-seo";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Utils
import { ImageOrientation } from "../components/utils/image-orientation";

const Page = styled.div`
  margin: 0 0 130px 0;

  & h1 {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    margin: 0 0 40px 0;
  }

  & p {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.05em;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & section {
    margin: 0 0 60px 0;
  }

  & ol {
    grid-column: 1 / 10;

    & li {
      & span {
        display: inline;

        & p {
          display: inline;
          line-height: 28px;
          margin: 0;
        }

        @media (max-width: 768px) {
          line-height: 24px;
        }

        &.year {
          font-size: 14px;
          letter-spacing: 0.06em;
        }
      }

      transition: 250ms color ease;

      &:hover {
        color: #4383ef;
      }
    }

    @media (max-width: 1075px) {
      grid-column: unset;
    }

    @media (max-width: 787px) {
      & li {
        margin: 0 0 1em 0;
      }
    }
  }

  & .active-press-item {
    grid-column: 10 / 13;
    height: calc(100vh - 200px);
    position: sticky;
    top: 120px;

    & img {
      max-width: 310px;
      margin: 0 0 0 auto;
    }

    @media (max-width: 1075px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    margin: 0 0 55px 0;
  }
`;

const DesktopFilters = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-column-gap: 40px;

  @media (max-width: 1350px) {
    grid-column-gap: 20px;
  }

  @media (max-width: 1100px) {
    display: flex;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }

  grid-column: 1 / 13;

  margin: 25px 0 20px 0;

  & button {
    margin: 0 15px 0 0;
    text-transform: capitalize;
    text-align: left;

    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    &:last-of-type {
      margin: 0;
    }

    & span {
      opacity: 0;
      transition: 250ms opacity ease;
    }

    &:hover,
    &.active-tag {
      & span {
        opacity: 1;
      }
    }

    & svg {
      margin: 0 7px 0 0;
    }

    &.view-all {
      @media (max-width: 1100px) {
        margin: 0 15px 0 0;
      }
    }
  }

  & .tags-container {
    grid-column: 2 / 12;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const MobileFilters = styled.div`
  margin: 0 0 20px 0;
  grid-column: 1 / 13;

  & .mobile-filters-container {
    & button {
      display: block;

      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05em;

      margin: 0 0 10px 0;
      text-transform: capitalize;
      text-align: left;

      color: #163262;

      & span {
        opacity: 0;
        transition: 250ms opacity ease;
      }

      &:hover,
      &.active-tag {
        & span {
          opacity: 1;
        }
      }

      & svg {
        margin: 0 7px 0 0;
      }
    }

    & .tags-container {
    }
  }

  &.visible {
    & .mobile-filters-container {
      position: absolute;

      top: 0;
      left: 0;
      right: 0;

      background-color: #5694fd;

      z-index: 10;

      padding: 124px 40px 20px 40px;

      @media (max-width: 768px) {
        padding: 107px 20px 20px 20px;
      }

      & button {
        color: #163262;

        &.toggle {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.06em;
        }
      }
    }
  }

  & .toggle {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.06em;
  }

  @media (max-width: 768px) {
    & .mobile-filters-container {
      & button {
        & span {
          display: none;
        }

        &.active-tag {
          & span {
            display: inline-block;
          }
        }
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;

  & .content-grid {
    grid-column: 1 / 12;

    &.top-row {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 40px;
    }

    &.bottom-row {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 40px;

      @media (max-width: 1075px) {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & .content-grid {
      grid-column: unset;

      &.top-row {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
      }
    }
  }
`;

const Press = ({ data }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useContext(
    PageBackgroundColor
  );
  const [activePressItem, setActivePressItem] = useState(null);

  const [activeTag, setActiveTag] = useState(`all`);

  // Mobile Filters
  const [isMobileFiltersVisible, setMobileFiltersVisible] = useState(false);
  const mobileFiltersRef = useRef(null);

  useClickAway(mobileFiltersRef, () => {
    setMobileFiltersVisible(false);
  });

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 1075px)");

  useEffect(() => {
    setPageBackgroundColor({
      text: `#163262`,
      background: `#F3FFA6`,
    });
  }, []);

  useEffect(() => {
    setMobileFiltersVisible(false);
  }, [activeTag]);

  useEffect(() => {
    if (isMobileFiltersVisible === true) {
      document.body.classList.add(`mobile-filters-open`);
      document.body.classList.add(`press`);
    } else {
      document.body.classList.remove(`mobile-filters-open`);
      document.body.classList.remove(`press`);
    }
  }, [isMobileFiltersVisible]);

  const allPressArticles = data.prismicPress.data.body.filter(article => {
    if (activeTag === `all`) {
      return article;
    } else {
      if (activeTag === article.primary.year) {
        return article;
      }
    }
  });

  const content = allPressArticles.map((article, index) => (
    <li
      key={`single_press_article_${index}`}
      onMouseEnter={() => setActivePressItem(index)}
      onMouseLeave={() => setActivePressItem(null)}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: article.primary.article.html,
        }}
      />
      ,<span className="year"> {article.primary.year}</span>
    </li>
  ));

  const tags = Array.from(
    new Set(
      data.prismicPress.data.body.map(article => article.primary.year).flat()
    )
  )
    .sort((a, b) => b - a)
    .map((tag, index) => (
      <button
        key={`single_news_tag_${index}`}
        onClick={() => setActiveTag(tag)}
        className={activeTag === tag ? `active-tag` : ``}
      >
        <span>(</span>
        {tag}
        <span>)</span>
      </button>
    ));

  return (
    <>
      <PageSEO
        title={data.prismicPress.data.title.text}
        description={null}
        image={null}
        url={`https://yinkashonibare.com${data.prismicPress.url}`}
      />
      <Page>
        <Grid>
          <div className="content-grid top-row">
            {isDesktop ? (
              <DesktopFilters>
                <button
                  onClick={() => setActiveTag(`all`)}
                  className={
                    activeTag === `all` ? `active-tag view-all` : ` view-all`
                  }
                >
                  <span>(</span>View All<span>)</span>
                </button>

                <div className="tags-container">
                  <div className="tags">{tags}</div>
                </div>
              </DesktopFilters>
            ) : (
              <MobileFilters
                className={isMobileFiltersVisible ? `visible` : `hidden`}
                ref={mobileFiltersRef}
              >
                <button
                  className="toggle open"
                  onClick={() => {
                    setMobileFiltersVisible(!isMobileFiltersVisible);
                  }}
                >
                  Filter {isMobileFiltersVisible ? `-` : `+`}
                </button>

                {isMobileFiltersVisible && (
                  <RemoveScroll>
                    <div className="mobile-filters-container">
                      <button
                        className="toggle close"
                        onClick={() => {
                          setMobileFiltersVisible(false);
                        }}
                      >
                        Filter -
                      </button>

                      <button
                        onClick={() => setActiveTag(`all`)}
                        className={
                          activeTag === `all`
                            ? `active-tag view-all`
                            : ` view-all`
                        }
                      >
                        <span>(</span>View All<span>)</span>
                      </button>

                      <div className="tags-container">
                        <div className="tags">{tags}</div>
                      </div>
                    </div>
                  </RemoveScroll>
                )}
              </MobileFilters>
            )}
          </div>
        </Grid>

        <Grid>
          <div className="content-grid bottom-row">
            <ol>{content}</ol>

            <div className="active-press-item">
              {activePressItem !== null &&
                allPressArticles[activePressItem].primary.image.fluid !==
                  null && (
                  <img
                    className={ImageOrientation(
                      allPressArticles[activePressItem].primary.image
                    )}
                    srcSet={
                      allPressArticles[activePressItem].primary.image.fluid
                        .srcSet
                    }
                    src={
                      allPressArticles[activePressItem].primary.image.fluid.src
                    }
                    alt={allPressArticles[activePressItem].primary.image.alt}
                  />
                )}
            </div>
          </div>
        </Grid>
      </Page>
    </>
  );
};

export default withPreview(Press);

export const query = graphql`
  {
    prismicPress {
      data {
        title {
          html
          text
        }
        body {
          ... on PrismicPressBodyArticle {
            id
            slice_type
            primary {
              article {
                html
              }
              year
              image {
                fluid {
                  srcSet
                  src
                }
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
      url
    }
  }
`;
